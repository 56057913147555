<template>
  <div class="home-container">
    <div class="header-container">
      <div class="header">
        <div class="logo"><img src="@/assets/1.png" alt="" /></div>
        <div class="titles">
          <li
            v-for="(item, index) in titles"
            :key="index"
            @click="scrollToPosition(item.id)"
          >
            <span>
              {{ item.title }}
            </span>
          </li>
        </div>
      </div>
    </div>

    <div class="banner-container">
      <div class="banner">
        <div class="desc">
          <p>AI증명사진어플</p>
          <p class="green">주민등록증/여권/이력서</p>
          <p>사진 제작</p>
        </div>

        <div>
          <img src="@/assets/4.png" alt="" srcset="" />
        </div>

        <div class="tips">
          <p>지금 다운로드하기</p>
        </div>
      </div>
    </div>

    <div class="good-container">
      <div class="good">
        <div class="title" id="0">장점</div>
        <div class="container">
          <div class="top">
            <div class="item" v-for="(item, index) in goods[0]">
              <img :src="item.img" alt="" srcset="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
          <div class="bottom">
            <div class="item" v-for="(item, index) in goods[1]">
              <img :src="item.img" alt="" srcset="" />
              <span>{{ item.title }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="usage-container">
      <div class="usage">
        <div class="title" id="1">사용방법</div>
        <div class="container">
          <div class="item" v-for="(item, index) in usage">
            <div class="item-title">
              <p>{{ item.title }}</p>
            </div>
            <img :src="item.img" alt="" srcset="" />
          </div>
        </div>
      </div>
    </div>

    <div class="download-container">
      <div class="download">
        <div class="title" id="2">앱 다운로드</div>
        <div class="container">
          <div class="logo">
            <img src="@/assets/logo.png" alt="" srcset="" />
          </div>

          <div class="logo-title">
            <p>AI증명사진어플</p>
          </div>

          <div class="download-tips">
            <p>
              30초만 예쁜 증명사진, 신분증, 여권, 운전면허증, 이력서, 반명함,
              각종 시험, 나라별 비자 사진을 가볍게 제작할 수 있습니다.
            </p>
          </div>

          <div class="download-types">
            <div class="appstore">
              <a @click="iosAppStore" href.prevent="">
                <img src="@/assets/appStore.png" alt="" srcset=""
              /></a>
            </div>
            <div class="googleplay">
              <a @click="googleAppStore" href.prevent=""
                ><img src="@/assets/googlePlay.png" alt="" srcset=""
              /></a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="comment-container">
      <div class="comment">
        <div class="title" id="3">리뷰</div>
        <div class="container">
          <div class="item" v-for="(item, index) in comment">
            <img :src="item.img" alt="" srcset="" />
            <p class="content">
              {{ item.content }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="spec-container">
      <div class="spec">
        <div class="title" id="4">상세 규격</div>
        <div class="desc">수백 가지 규격을 제공하겠습니다</div>
        <div class="container">
          <div class="item" v-for="(item, index) in specs">
            <div class="left">
              <div class="left-title">
                <span class="left-title">{{ item.title }}</span>
                <span class="hot">HOT</span>
              </div>

              <p class="spec-detail">
                {{ item.spec }}
              </p>
            </div>

            <div class="right">
              <img :src="item.img" alt="" srcset="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-container">
      <div class="footer">
        <img src="@/assets/logo.png" alt="" srcset="" />
        <p class="title">AI증명사진어플</p>
        <p class="contact">
          고객센터:idphotoweb.service@leeta.ai<br /><a
            href="https://bwr51pxqif.feishu.cn/docx/Ck8tdvBZQocmQgxv2iSc9eA6nkb"
            >개인정보 처리방침</a
          >
        </p>
        <p class="copyright">
          @Copyright 2023 LEETA TECHNOLOGY PTE.LTD.<br />
          All rights reserved
        </p>
      </div>
    </div>

    <div class="download-btn" @click="downloadApp">
      <img src="@/assets/1.png" alt="" />
      <span>지금 다운로드하기</span>
    </div>
  </div>
</template>


<script>
import UAParser from "ua-parser-js";
import { event } from "vue-gtag";

export default {
  data() {
    return {
      titles: [
        { title: "장점", id: "0" },
        { title: "사용방법", id: "1" },
        { title: "앱 다운로드", id: "2" },
        { title: "리뷰", id: "3" },
        { title: "상세 규격", id: "4" },
      ],

      goods: [
        [
          { img: require("@/assets/5.png"), title: "제한 없은 촬영 횟수" },
          { img: require("@/assets/10.png"), title: "파격가" },
        ],
        [
          { img: require("@/assets/3.png"), title: "30초만에 제작 완성" },
          { img: require("@/assets/9.png"), title: "수백종 사진 규격" },
        ],
      ],

      usage: [
        { img: require("@/assets/8.png"), title: "1.용도" },
        { img: require("@/assets/7.png"), title: "2.촬용/업로드" },
        { img: require("@/assets/6.png"), title: "3.배경 제거 정장 바꿈 뽀샵" },
      ],

      comment: [
        {
          content:
            "유료지만 돈값함 여권 재발급 때문에 셀프증명 픽미 등 등 온갖 어플 다 깔아봤는데 여러 조건 들이 안 맞아서 파일 저장까지 하는데 실패함.. 진짜 마지막 도전이라 생각하 고 속는셈 치고 유료 결제해봣는데 え 결과물 저장까지 5분도 안 걸림 구 비자 사진도 이걸로 해결 할 슈 잇으니 까 얘가 최고인듯요…",
          img: require("@/assets/head1.jpeg"),
        },
        {
          content:
            "증명사진 앱이 있는지도 몰랐는데 네 이버에 찾고 하다보니 완전 쉽게 되어 있는 앱으로 사진을 뽑고 하니 되게 간 단해서 좋았습니다. 이 어플 많이 쓸 것 같아요~~~",
          img: require("@/assets/head2.jpeg"),
        },
        {
          content:
            "스마트폰과 시대에 딱 알맞는 정말 유 용하고 똑똑한 앱입니다. 코로나 완화 되면서 해외로 해외로 나가는 많은 사 람들 사이에 여권 사진을 새로 찍어 여 권 갱신하는데 어마어마한 시간을 써 야 하더라구요. 여권 발급하는 구청 앞 사진관에 가니 인화 대기만 40명  아 AI증명사진어플 덕분에 40명 대기 안하고 아 주 수월히 여권사진 마련합니다!!",
          img: require("@/assets/head3.jpeg"),
        },
      ],

      specs: [
        {
          title: "주민등록증",
          img: require("@/assets/b.png"),
          spec: "35mm x 45mm",
        },
        {
          title: "여권사진",
          img: require("@/assets/a.png"),
          spec: "35mm x 45mm",
        },
        {
          title: "운전면허증",
          img: require("@/assets/d.png"),
          spec: "35mm x 45mm",
        },
        {
          title: "이력서 (반명함 사진)）",
          img: require("@/assets/c.png"),
          spec: "30mm x 40mm",
        },
      ],
    };
  },

  methods: {
    iosAppStore() {
      event("打开apple应用商店");
      window.location.href = "https://apps.apple.com/kr/app/id6467190546?l=ko";
    },
    googleAppStore() {
      event("打开google应用商店");
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.leqi.idphoto_asia&hl=ko&gl=kr";
    },

    scrollToPosition(id) {
      document.getElementById(id).scrollIntoView();
    },

    downloadApp() {
      const osName = new UAParser().getResult().os.name;
      event(`${osName}-点击下载按钮`);
      if (osName === "iOS") {
        window.location.href =
          "https://apps.apple.com/kr/app/id6467190546?l=ko";
      } else if (
        osName === "BlackBerry" ||
        osName === "Windows Phone" ||
        osName === "Android"
      ) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.leqi.idphoto_asia&hl=ko&gl=kr";
      } else {
        this.scrollToPosition(2);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home-container {
  .header-container {
    background-color: #000;
    display: flex;
    justify-content: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 60px;
    z-index: 99999;
    .header {
      display: flex;
      justify-content: center;
      width: 375px;
      .logo {
        display: flex;
        align-items: center;
        img {
          width: 30px;
        }
      }
      .titles {
        display: flex;
        width: 300px;
        justify-content: space-evenly;
        li {
          float: left;
          list-style: none;
          cursor: pointer;
          span {
            font-size: 14px;
            color: #fff;
            line-height: 60px;
          }
        }
      }
    }
  }

  .banner-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
    background: linear-gradient(-45deg, #c2c9f9, #ffe8e8);
    .banner {
      height: 475px;
      width: 375px;
      position: relative;
      .desc {
        font-size: 32px;
        font-weight: bold;
        color: #333;
        position: absolute;
        margin: 52px 0 0 20px;
        .green {
          color: #5970ff;
        }
      }

      .tips {
        background-color: #fff;
        color: #333;
        font-weight: 500;
        font-size: 16px;
        display: inline-block;
        position: absolute;
        right: 180px;
        bottom: 30px;
        padding: 15px 22px;
        border-radius: 35px;
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.08);
      }

      img {
        position: absolute;
        width: 177px;
        float: right;
        bottom: 0;
        right: 45px;
      }
    }
  }

  .good-container {
    display: flex;
    justify-content: center;
    padding: 40px 0;
    .good {
      width: 350px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        margin-left: 24px;
        font-weight: bold;
        color: #333;
        font-size: 28px;
      }

      .container {
        height: 440px;
        width: 360px;
        // background-color: blue;
        .top,
        .bottom {
          display: flex;
          justify-content: space-around;
          margin-bottom: 30px;
          .item {
            width: 180px;
            // background-color: red;
            display: flex;
            flex-direction: column;
            // justify-content: center;
            align-items: center;
            img {
              width: 140px;
              // object-fit: contain;
              background-color: #fff;
              display: block;
            }

            span {
              width: 140px;
              font-size: 16px;
              font-weight: 500;
              display: block;
              text-align: center;
              margin-top: 4px;
              color: #333;
            }
          }
        }
      }
    }
  }

  .usage-container {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background-color: #fff8f8;
    .usage {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        margin-bottom: 16px;
        margin-left: 24px;
        font-weight: bold;
        color: #ff8484;
        font-size: 32px;
      }
      .container {
        //   background-color: black;
        width: 375px;
        // height: 800px;
        .item {
          width: 327px;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 0px 10px rgba(0, 0, 0, 0.08);
          margin: 24px;
          padding-bottom: 30px;
          .item-title {
            background-color: #fff0f0;
            display: inline-block;
            border-radius: 6px;
            margin: 25px 28px;
            width: 270px;
            p {
              color: #ff8484;
              margin: 13px 0 13px 30px;
              font-size: 16px;
              font-weight: bold;
            }
          }

          img {
            width: 200px;
            object-fit: contain;
            margin: auto 65px;
          }
        }
      }
    }
  }

  .download-container {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    .download {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        margin-left: 24px;
        font-weight: bold;
        color: #333;
        font-size: 32px;
      }
      .container {
        width: 375px;
        height: 360px;
        .logo {
          margin: 24px;
          img {
            width: 58px;
          }
        }

        .logo-title > p {
          font-size: 28px;
          font-weight: bold;
          margin: 24px;
        }

        .download-tips {
          width: 327px;
          margin: 24px;
          p {
            font-size: 16px;
            font-weight: 500;
            color: #333;
            line-height: 1.5;
          }
        }

        .download-types {
          display: flex;
          margin-top: 20px;
          justify-content: space-evenly;
          .appstore > a > img {
            width: 146px;
          }

          .googleplay > a > img {
            width: 170px;
          }
        }
      }
    }
  }

  .comment-container {
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    padding: 40px 0;
    .comment {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        margin-bottom: 16px;
        margin-left: 24px;
        font-weight: bold;
        color: #333;
        font-size: 32px;
      }
      .container {
        //   background-color: black;
        width: 375px;
        // height: 800px;
        .item {
          //   width: 327px;
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 0px 20px rgba(0, 0, 0, 0.08);
          margin: 24px;
          display: flex;
          justify-content: space-between;
          padding: 20px;
          img {
            width: 40px;
            height: 40px;
          }

          .content {
            //   background-color: blue;
            width: 230px;
            font-size: 16px;
            color: #333;
            font-weight: 500;
          }
        }
      }
    }
  }

  .spec-container {
    padding: 40px 0;
    display: flex;
    justify-content: center;
    background-color: #fff;
    .spec {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .title {
        margin-bottom: 16px;
        margin-left: 24px;
        font-weight: bold;
        color: #ff8484;
        font-size: 32px;
      }

      .desc {
        font-size: 16px;
        font-weight: 500;
        color: #000;
        margin: 0 24px;
      }

      .container {
        //   background-color: black;
        width: 375px;
        // height: 800px;
        .item {
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 0px 20px rgba(0, 0, 0, 0.12);
          margin: 24px;
          display: flex;
          justify-content: space-between;
          padding: 20px;
          .left {
            display: flex;
            flex-direction: column;
            justify-content: space-around;
            height: 60px;
            // background-color: red;
            // margin: auto 0;
            .left-title {
              display: flex;
              justify-content: center;
              .left-title {
                color: #333;
                font-size: 16px;
                font-weight: bold;
                background-color: #fff;
                display: inline-block;
                margin-right: 3px;
                white-space: nowrap;
                max-width: 150px;
                text-overflow: ellipsis;
                overflow: hidden;
              }

              .hot {
                background-color: #e64e34;
                font-size: 14px;
                color: #fff;
                font-weight: 500;
                display: inline-block;
                border-radius: 3px;
                width: 45px;
                height: 20px;
                text-align: center;
                line-height: 20px;
              }
            }

            .spec-detail {
              color: #a6a6a6;
              font-size: 14px;
            }
          }

          .right {
            img {
              width: 77px;
              //   margin-right: 20px;
            }
          }
        }
      }
    }
  }

  .footer-container {
    padding: 40px 0 100px 0;
    display: flex;
    justify-content: center;
    background-color: #f5f5f5;
    .footer {
      width: 325px;
      margin: 35px;
      img {
        width: 40px;
        margin-bottom: 16px;
      }

      .title {
        color: #1939ff;
        font-weight: bold;
        font-size: 20px;
        margin-bottom: 32px;
      }

      .contact {
        color: #333;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 49px;
        a {
          color: #333;
        }
      }

      .copyright {
        color: #a6a6a6;
        font-size: 14px;
      }
    }
  }

  .download-btn {
    position: fixed;
    bottom: 40px;
    height: 60px;
    width: 300px;
    background: linear-gradient(to right, #1939ff, #5970ff);
    border-radius: 30px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 0 20px rgba(3, 61, 137, 0.25);
    img {
      width: 40px;
    }

    span {
      margin-left: 5px;
      color: #fff;
      font-size: 20px;
      font-weight: bold;
    }
  }
}
</style>