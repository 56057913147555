<template>
<home></home>
</template>

<script>
import Home from '@/components/Home.vue'
export default {
  name: 'App',
  components: {
    Home
  }
}
</script>

<style>
*{
  margin: 0;
  padding: 0;
}
</style>
