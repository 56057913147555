import { createApp } from 'vue'
import App from './App.vue'
import "@/util/index.css"
import VueGtag from 'vue-gtag'

const app = createApp(App);
app.use(VueGtag, {
    config: { id: "G-K0H30CEZJ3" },
})
app.mount('#app')
